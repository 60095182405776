import React from 'react'

const HR: React.FC<
  React.DetailedHTMLProps<React.HTMLAttributes<HTMLHRElement>, HTMLHRElement>
> = ({ children, className, ...other }) => {
  return (
    <hr
      className={
        'my-6 border-0 border-t border-brand-bg-border' +
        (className ? ` ${className}` : '')
      }
      {...other}
    />
  )
}

export default HR
